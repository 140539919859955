import React, { useEffect, useRef, useState } from 'react';
import { BsFillArrowUpSquareFill, BsFillArrowDownSquareFill,  
    BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';

function Tetris() {

    const canvasRef = useRef(null);
    const gameStarted = useRef(false);
    const table = useRef([]);
    
    const time = useRef(0);
    const BLOCK_SIZE = 15;
    const COUNT_BLOCKS = 20;
    const ADITIONAL_BLOCKS = 5;
    const CANVA_WIDTH = BLOCK_SIZE * COUNT_BLOCKS;
    const CANVA_HEIGHT = BLOCK_SIZE * (COUNT_BLOCKS + ADITIONAL_BLOCKS);
    const score = useRef(0);
    const shapes = [
        [
            [0,0,0],[0, 1, 0], [1, 1, 1], 
        ], [
            [0, 0], [1, 1]
        ], [
            [1, 1], [0, 1]
        ], [
           [0,0,0], [0, 0, 1], [1, 1, 1]
        ], [
           [0,1,0], [1, 1, 1], [0, 1, 0]
        ], [
           [0,0,0], [1, 0, 0], [1, 1, 1]
        ], [
            [1,1], [1, 1]
        ]
    ]

    const currentFrameRate = useRef(0);
    let frames = 0
    function animate() {
        window.requestAnimationFrame(animate)
        frames++
    }
    animate();

    setInterval(() => {
        currentFrameRate.current = frames
        frames = 0
      }, 1000)
    
    const piece = {
        position: { x: 5, y: 2 },
        shape: [
            [0, 0],
            [1, 1],
        ]
    };
    
    const removeLine = (grid) => {
        const rowsToRemove = [];

        grid.forEach((row, y) => {
            if (row.every(value => value === 1)) {
                rowsToRemove.push(y);
            }
        });
        score.current += rowsToRemove.length * 10;
        rowsToRemove.forEach((rowToRemove) => {
            grid.splice(rowToRemove, 1);
            grid.unshift(new Array(COUNT_BLOCKS).fill(0));
        });
    }

    const drawGrid = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = CANVA_WIDTH;
        canvas.height = CANVA_HEIGHT;
        ctx.scale(BLOCK_SIZE, BLOCK_SIZE);
        const grid = table.current;
        grid.forEach((row, y) => {
            row.forEach((value, x) => {
                if (value === 1) {
                    //ctx.fillStyle = piece.shape.color;
                    ctx.fillRect(x, y, 1, 1);
                }
            });
        });
        ctx.font = "1px arial";
        ctx.textBaseline = "top";
        ctx.fillText(`score: ${score.current}`, 1, 1)
        ctx.fillText(`fps: ${currentFrameRate.current}`, 7, 1)
        ctx.fillText(`time: ${time.current}`, 12, 1)
        piece.shape.forEach((row, y) => {
            row.forEach((value, x) => {
                if (value === 1) {
                    piece.shape.color = piece.shape.color ?? getRandomColor();
                    ctx.fillStyle = piece.shape.color;
                    ctx.fillRect(piece.position.x + x, piece.position.y + y, 1, 1);
                }
            });
        });

        return canvasRef;
    }

    const startGrid = () => {
        gameStarted.current = true;
        const grid = [];
        for (let i = 0; i < (COUNT_BLOCKS + ADITIONAL_BLOCKS); i++) {
            grid.push(new Array(COUNT_BLOCKS).fill(0));
        }
        table.current = grid;
        return grid;
    }

    const checkIfOnBoard = (grid) => {
        return piece.shape.find((row, y) => {
            return row.find((value, x) => {
                return value !== 0 && grid[piece.position.y + y]?.[piece.position.x + x] !== 0;
            });
        });
    }

    const getRandomColor = () => {
        const colors = [
            "#673AB7", "#673AB7", "#E91E63", 
            "#4CAF50", "#FFC107", "#FF5722",
            "#9E9E9E", "#9C27B0"
        ];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    const fitPiece = (grid) => {
        piece.shape.forEach((row, y) => {
            row.forEach((value, x) => {
                if (value === 1) {
                    grid[piece.position.y + y][piece.position.x + x] = value;
                    table.current = grid;
                }
            });
        });
        piece.shape.color = getRandomColor();
        piece.shape = shapes[Math.floor(Math.random() * shapes.length)];
        piece.position.x = 5;
        piece.position.y = 5;
    }

    useEffect(() => {
        const onKeyDown = (event) => {
            if (!gameStarted.current) return;
            if (event.key === 'ArrowRight') {
                piece.position.x++;
                if (checkIfOnBoard(table.current)) {
                    piece.position.x--;
                }
                canvasRef.current = drawGrid().current;
            }
            if (event.key === 'ArrowLeft') {
                piece.position.x--;
                if (checkIfOnBoard(table.current)) {
                    piece.position.x++;
                }
                canvasRef.current = drawGrid().current;
            }
            if (event.key === 'ArrowUp') {
                let rotatedPiece = [];
                let x, y;
                for (x = 0; x < piece.shape.length; x++) {
                    let row = [];
                    for(y = piece.shape[x].length - 1; y >= 0; y--) {
                        row.push(piece.shape[y][x]);
                    }
                    rotatedPiece.push(row);
                }
                piece.shape = rotatedPiece;

                canvasRef.current = drawGrid().current;

            }
            if (event.key === 'ArrowDown') {
                piece.position.y++;
                if (checkIfOnBoard(table.current)) {
                    piece.position.y--;
                    fitPiece(table.current);
                    removeLine(table.current);
                }

                canvasRef.current = drawGrid().current;
            }
        }

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [canvasRef, checkIfOnBoard, gameStarted, piece, table]);

    setInterval(() => {
        if (!gameStarted.current) return;
        piece.position.y++;
        if (checkIfOnBoard(table.current)) {
            piece.position.y--;
            fitPiece(table.current);
            removeLine(table.current);
        }
        canvasRef.current = drawGrid().current;
        time.current++;
    }, 1000);

    const Canvas = () => {
        return (<canvas className="tetris-grid"  ref={canvasRef} />);
    };

    return (
        <>
            <h6 className="title-header">Tetris</h6>
            <div className='row'>
            <div className="six columns bordered-right centered">
                <Canvas />
                <button className="btn btn-primary" onClick={() => startGrid()}>Start Game</button>
                </div>
            <div className="six columns">
            <h6 className="title-header">Tetris</h6>
            <p>Lorem ipsum dolor </p>
                <div className='row button-icons'>
                    <div className="two columns centered">
                        <BsFillArrowUpSquareFill/>
                    </div>
                    <div className="two columns centered">
                        <BsFillArrowLeftSquareFill />
                    </div>
                    <div className="two columns centered">
                        <BsFillArrowRightSquareFill />
                    </div>
                    <div className="two columns centered">
                        <BsFillArrowDownSquareFill />
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default Tetris;
