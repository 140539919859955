import React, { useEffect, useState } from 'react';
import CopyClipboard from '../components/CopyClipboard/CopyClipboard';
import SettingsOption from '../components/SettingsOption/SettingsOption';
import { BsLightningChargeFill } from 'react-icons/bs';
import { getNewUlid } from '../utils/ulidTools'

function RandomIdGenerator() {
    const [lowerCase, setLowerCase] = useState(true);
    const [prefix, setPrefix] = useState('');
    const [length, setLength] = useState(5);
    const [amount, setAmount] = useState(1);
    const [idsList, setIdsList] = useState([]);

    const randomIds = () => {
        // get randoms ids based in amount
        let ids = [];
        for (let i = 0; i < amount; i++) {
            const rid = Math.random().toString(36).substring(2, parseInt(length)+2);
            const id = prefix + (lowerCase ? rid.toLowerCase() : rid.toUpperCase());
            ids.push(id);
        }
        setIdsList(ids);
    };

    useEffect(() => {
        setIdsList([]);
        randomIds();
    }, [amount, length, prefix, lowerCase]);

    return (
        <>
            <h6 className="title-header">RANDOM ID GENERATOR</h6>
            <p>Use this tool to generate random ids with defined prefixes.</p>
            <br/>
            <div className="row">
                <div className="six columns bordered-right">
                    <p className='custom-p'>Number of ID's</p>
                    <input className='custom-form-item' type="text" onInput={(e) => setAmount(e.target.value)} placeholder='0' required></input>
                    <p className='custom-p'>Custom prefix</p>
                    <input className='custom-form-item' type="text" onInput={(e) => setPrefix(e.target.value)} placeholder='0' required></input>
                    <p className='custom-p'>Random length</p>
                    <input className='custom-form-item' type="text" onInput={(e) => setLength(e.target.value)} placeholder='0' required></input>

                    <SettingsOption title="IDs toLowerCase()" isChecked={lowerCase} onClickFn={setLowerCase} />


                </div>
                <div className="six columns">
                    <h6 className="title-header">RESULT </h6>
                    {idsList.map((id, index) => (
                        <div key={index} className="row">
                            <div className="six columns">
                                <p className='custom-p'>{id}</p>
                                </div>
                            </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RandomIdGenerator;
