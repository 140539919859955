import { ulid } from 'ulid'

export function getNewUlid(lowerCase){
    let _ulid = lowerCase ?  ulid().toLowerCase() :  ulid();
    let log = localStorage.getItem('ulidLog');
    let item = {
        id: _ulid,
        date: new Date(),
        lowerCase: lowerCase
    }
    if (log) {
        log = JSON.parse(log);
        log.unshift(item);
        localStorage.setItem('ulidLog', JSON.stringify(log));
    } else {
        localStorage.setItem('ulidLog', JSON.stringify([item]));
    }
    return _ulid;
}