import React, { useState } from "react";
import { BsFillSuitHeartFill } from 'react-icons/bs';
import { DiReact } from "react-icons/di";
import "./Footer.css";
import packageJson from '../../../package.json';

function Footer(props) {
    return (
        <footer className="footer">
        <div className="row">
          <div className="twelve columns">
            <p className="footer-text">
              <span className="tooltip tooltip--top" data-tooltip="Site under construction"><DiReact className="footer-icon"/></span> | <span  className="tooltip tooltip--top" data-tooltip="Last build: ">v{packageJson.version} </span>| With <BsFillSuitHeartFill/> by Giovanni
            </p>
          </div>
        </div>
      </footer>
    );
}

export default Footer;
