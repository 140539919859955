import { io } from 'socket.io-client';

let socket;
export const initiateSocketConnection = (token) => {
    socket = io('wss://ws-planning-poker.onrender.com', {
        auth: {
            token
        },
        transports: ["websocket"]
    });
    console.log(`Connecting socket...`);
}
export const disconnectSocket = () => {
    console.log('Disconnecting socket...');
    if (socket) socket.disconnect();
}

export const subscribeToChat = (cb) => {
    socket.emit('my message', 'Hello there from React.');
    if (!socket) return (true);
    socket.on('my broadcast', msg => {
        console.log('Websocket event received!');
        return cb(null, msg);
    });
}


export const subscribeToUsers = (cb) => {
    if (!socket) return (true);
    socket.on('users', usr => {
        console.log('Room event received!');
        return cb(null, usr);
    });
}

export const subscribeToRoom = (cb) => {
    console.log('subscribeToRoom', socket);
    if (!socket) return (true);
    socket.on('data', data => {
        return cb(null, data);
    });
}

export const joinRoom = (roomId) => {
    socket.emit('join', roomId);
}

export const voteIssue = (issueId, value, roomId) => {
    socket.emit('vote', { issueId, value, roomId });

}
