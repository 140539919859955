import React, { useState } from 'react';
import CopyClipboard from '../components/CopyClipboard/CopyClipboard';
import SettingsOption from '../components/SettingsOption/SettingsOption';
import { BsLightningChargeFill } from 'react-icons/bs';
import { getNewUlid } from '../utils/ulidTools'

function UlidGenerator() {
    const [lowerCase, setLowerCase] = useState(true);
    const [multiple, setMultiple] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    
    const getUlid = () => getNewUlid(lowerCase);

    const [id, setUlid] = useState(getUlid());

    let getUlids = () => {
        if (multiple) {
            let id = [];
            for (let i = 0; i < 10; i++) {
                id.push(getUlid())
            }
            return id;
        }else{
            return [];
        }
    }

    const [ids, setUlids] = useState(getUlids());

    const history = localStorage.getItem('ulidLog');
    const listItems = (multiple && ids.length > 0) ? ids.map((d) => <div className="custom-panel" key={d}>{d} <CopyClipboard /></div>) : null;
    const historyLog = (showHistory && history) ? JSON.parse(history).map((d) => <p className='ulid-text-simple'>{d.id} - <small>{d.date}</small></p>) : null;
    return (
        <>
            <h6 className="title-header">ULID GENERATOR</h6>
            <p>Lorem ipsum dolor </p>
            <div className="row">
                <div className="six columns bordered-right">
                    {!multiple ? (
                        <>
                            <div className="custom-panel">
                                {id} <CopyClipboard params={id} />
                            </div>
                            <div className="row mt-10">
                                <div className="six columns">
                                    <button onClick={() => setUlid(getUlid)}>new ULID()</button>
                                </div>
                            </div>
                            {historyLog}
                        </>
                    ) : (
                        <>
                            {listItems}
                            <div className="row mt-10">
                                <div className="six columns">
                                    <button onClick={() => setUlids(getUlids)}>new ULIDs()</button>
                                </div>
                            </div>
                        </>
                    )
                    }

                </div>
                <div className="six columns">
                    <h6 className="title-header">Settings </h6>
                    <p>Lorem ipsum dolor sit amet</p>
                    <SettingsOption title="ULID toLowerCase()" isChecked={lowerCase} onClickFn={setLowerCase} />
                    <SettingsOption title="generate multiple ULIDs" isChecked={multiple} onClickFn={setMultiple} />
                    <SettingsOption title="show history" isChecked={showHistory} onClickFn={setShowHistory} />

                </div>
            </div>
        </>
    );
}

export default UlidGenerator;
