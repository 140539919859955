import React, { useEffect, useState } from 'react';
import SettingsOption from '../components/SettingsOption/SettingsOption';
import fmt2json from '../utils/formatJson';

function JsonFormat() {
    const [value, setValue] = useState('');
    const [formatted, setFormatted] = useState(false);
    const [valueFormatted, setValueFormatted] = useState('');

    useEffect(() => {
        setValueFormatted(fmt2json(value, { withDetails: true }));
        console.log(fmt2json(value, { withDetails: true }));
    }, [formatted, value]);

    return (
        <>
            <h6 className="title-header">JSON FORMAT</h6>
            <p>{ valueFormatted.message }</p>
            
            <div className="row">
                <div className="twelve columns bordered-right">
                    <div className="custom-panel">
                       <textarea placeholder='text...' value={value} className="textAreaInput" onChange={e => setValue(e.target.value)}></textarea>
                    </div>
                    
                    <div className="custom-panel">
                       <textarea placeholder='text...' value={valueFormatted.result} className="textAreaInput" onChange={e => setValueFormatted(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JsonFormat;
