import React from 'react';
import './SettingsOption.css';

const SettingsOption = (props) => {
    const {isChecked, customYesOption, customNoOption, onClickFn, title, isDisabled} = props;
    const yesOption = customYesOption ?? 'YES';
    const noOption = customNoOption ?? 'NO';

    return (
        <div className="row">
            <div className="settings-check">
                <div className="button no-border" onChange={() => null} onClick={() => onClickFn(!isChecked)}>
                    <input type="checkbox" className="checkbox" defaultChecked={isChecked} disabled={isDisabled}/>
                    <div className="knobs" data-yes={yesOption} data-no={noOption}></div>
                    <div className="layer"></div>
                </div>
            </div>
            <div className="settings-title title-custom mt-5">
                {title}
            </div>
        </div>
    );
}

export default SettingsOption;