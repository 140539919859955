import React, { useState } from 'react';
import { BsFillClipboardFill, BsFillClipboardCheckFill } from 'react-icons/bs';
import './CopyClipboard.css';

const CopyClipboard = (props) => {
    const [clicked, setClicked] = useState(false);
    const copyToClipBoard = (id) => {
        navigator.clipboard.writeText(id);
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
        }, 1000);
    }
    return (
            <button onClick={() => copyToClipBoard(props.params)} className="copy-clipboard no-border">
                {clicked ? <BsFillClipboardCheckFill /> : <BsFillClipboardFill />}
            </button>
        );
}

export default CopyClipboard;