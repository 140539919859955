import React, { useState } from "react";
import { BsLightningChargeFill } from 'react-icons/bs';
import "./Menu.css";
import { Link, useLocation } from 'react-router-dom';

function Menu(props) {
    //const [open, setOpen] = useState(false);
    const { open, setOpen } = props;
    const location = useLocation();
    const menuItems = [
        { label: 'Home', to: '/' },
        {
            label: 'WHAT I USE', to: '#', subMenu: [
                { label: 'VSCode Extensions', to: '/vscode-extensions' },
            ],
        },
        {
            label: 'Developer Tools', to: '#', subMenu: [
                { label: 'Ulid Generator', to: '/ulid-tool' },
                { label: 'Random ID', to: '/random-id' },
                { label: 'String Format', to: '/string-format' },
                { label: 'JSON Format', to: '/json-format' },
            ]
        },
        {
            label: 'POCs', to: '#', subMenu: [
                { label: 'Tetris', to: '/tetris' },
                { label: 'Planning Poker', to: '/planning-poker' },
                { label: 'Custom Rev Apps', to: '/blank-page' },
            ]
        },
        { label: 'Public APIs', to: '/blank-page' },
    ];

    return (
        <nav className="custom-menu">
            <ul className={`menu ${open ? "open" : ""}`}>
                {menuItems.map((item) => (
                    <li key={item.to}>
                        <Link to={item.to}>
                            {item.label} {location.pathname === item.to ? <BsLightningChargeFill /> : ''}
                        </Link>

                        {item.subMenu && (
                            <ul>
                                {item.subMenu.map((subItem) => (
                                    <li key={subItem.to}>
                                        <Link to={subItem.to}>
                                            {subItem.label} {location.pathname === subItem.to ? <BsLightningChargeFill /> : ''}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Menu;
