import React, { useEffect, useState } from 'react';
import CopyClipboard from '../components/CopyClipboard/CopyClipboard';
import SettingsOption from '../components/SettingsOption/SettingsOption';
import { BsLightningChargeFill } from 'react-icons/bs';
import { getNewUlid } from '../utils/ulidTools'

function StringFormat() {
    const [value, setValue] = useState('');
    const [lowerCase, setLowerCase] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [capitalize, setCapitalize] = useState(false);

    const reseSettingstOptions = () => {
        setLowerCase(false);
        setUpperCase(false);
    }

    function capitalizeText(word) {
        return word
          .toLowerCase()
          .replace(/\w/, firstLetter => firstLetter.toUpperCase());
      }

    useEffect(() => {
        reseSettingstOptions();
        if (lowerCase) {
            setValue(value.toLowerCase());
        }
        else if (upperCase) {
            setValue(value.toUpperCase());
        }
        else if(capitalize) {
            setValue(capitalizeText(value));
        }
        console.log(value);
    }, [lowerCase, upperCase, value, capitalize]);

    return (
        <>
            <h6 className="title-header">STRING FORMAT</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan risus et pulvinar ornare. Maecenas ante metus, ullamcorper id maximus quis, interdum eu nisl.</p>

            <div className="row">
                <div className="eight columns bordered-right">
                    <div className="custom-panel">
                       <textarea placeholder='text...' value={value} className="textAreaInput" onChange={e => setValue(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="four columns">
                    <h6 className="title-header">Settings</h6>
                    <p>Lorem ipsum dolor sit amet</p>
                    <SettingsOption title="lowercase" isChecked={lowerCase} onClickFn={setLowerCase} />
                    <SettingsOption title="UPPERCASE" isChecked={upperCase} onClickFn={setUpperCase} />
                    <SettingsOption title="Capitalized" isChecked={capitalize} onClickFn={setCapitalize} />
                </div>
            </div>
        </>
    );
}

export default StringFormat;
