import React, { useState } from 'react';

function VSExtensions() {
    return (
        <>
            <h6 className="title-header">VISUAL STUDIO CODE EXTENSIONS </h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan risus et pulvinar ornare. Maecenas ante metus, ullamcorper id maximus quis, interdum eu nisl.</p>
            <div className='row'>
                <div className="four columns vs-extension">
                    <div className="image-vs-extension">
                        <img className='img100-vs-extension' src="https://github.githubassets.com/images/modules/site/social-cards/copilot-ga.png" alt="github-copilot" />
                    </div>
                    <div className="content-vs-extension">
                        <h6 className="title-vs-extension">GitHub Copilot</h6>
                    </div>
                </div>
                <div className="four columns vs-extension">
                    <div className="image-vs-extension">
                        <img className='img100-vs-extension' src="https://raw.githubusercontent.com/gitkraken/vscode-gitlens/main/images/docs/gitlens-logo-anybg.png" alt="github-copilot" />
                    </div>
                    <div className="content-vs-extension">
                        <h6 className="title-vs-extension">GitLens — Git superchargeg</h6>
                    </div>
                </div>
                <div className="four columns vs-extension">
                    <div className="image-vs-extension">
                        <img className='img100-vs-extension' src="https://pbs.twimg.com/media/DHw9q77UwAEcnNq.jpg:large" alt="github-copilot" />
                    </div>
                    <div className="content-vs-extension">
                        <h6 className="title-vs-extension">Import Cost</h6>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VSExtensions;
