import React, { useState } from 'react';
import UlidGenerator from './pages/UlidGenerator'
import Home from './pages/Home'
import Footer from './components/Footer/Footer';
import VSExtensions from './pages/VSExtensions'
import Menu from './components/Menu/Menu'
import SocialLink from './components/SocialLink/SocialLink'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BsTwitter, BsSuitHeart, BsInstagram, BsLink, BsGithub, BsLinkedin } from 'react-icons/bs';
import { SiLeetcode } from 'react-icons/si';
import Hamburger from './components/Menu/Hamburger';
import StringFormat from './pages/StringFormat';
import PlanningPoker from './pages/PlanningPoker';
import JsonFormat from './pages/JsonFormat';
import RandomIdGenerator from './pages/RandomIdGenerator';
import Tetris from './pages/Tetris';
import BlankPage from './pages/BlankPage';

function App() {
  const [openMenu, setOpenMenu] = useState(false);
  
  return (
    <>
      <div className="header">
        <div className="row header-content">
          <Hamburger setOpen={setOpenMenu} open={openMenu} />
          <div className="logo">
            <h1 className="title-header">ifgiovanni</h1>
          </div>
          <div className="social">
            <SocialLink tooltipText="LinkedIn" link="https://www.linkedin.com/in/giovanniorellana" icon={<BsLinkedin />} />
            <SocialLink tooltipText="GitHub" link="https://github.com/ifgiovanni" icon={<BsGithub />} />
            <SocialLink tooltipText="LeetCode" link="https://leetcode.com/ifgiovanni" icon={<SiLeetcode />} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Router>
            <div className="three columns">
              <Menu setOpen={setOpenMenu} open={openMenu} />
            </div>
            <div className="nine columns">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/ulid-tool" element={<UlidGenerator />} />
                <Route path="/random-id" element={<RandomIdGenerator />} />
                <Route path="/string-format" element={<StringFormat />} />
                <Route path="/json-format" element={<JsonFormat />} />
                <Route path="/vscode-extensions" element={<VSExtensions />} />
                <Route path="/planning-poker" element={<PlanningPoker />} />
                <Route path="/tetris" element={<Tetris />} />
                <Route path="/blank-page" element={<BlankPage />} />
              </Routes>
            </div>
          </Router>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default App;
