import React, { useState, useEffect, useRef } from 'react';
import { BsCheck2Circle, BsFillCircleFill } from 'react-icons/bs';
import { FaFacebookF, FaGithub, FaTwitter } from 'react-icons/fa';
import { MdVerified, MdKeyboardBackspace } from 'react-icons/md';
import { BsPhoneFlip } from 'react-icons/bs';
import { FacebookLoginButton, AppleLoginButton, GithubLoginButton, GoogleLoginButton, TelegramLoginButton, DiscordLoginButton } from "react-social-login-buttons";
import { TbExternalLink } from 'react-icons/tb';
import { initiateSocketConnection, disconnectSocket, sendMessage, voteIssue, joinRoom, subscribeToUsers, subscribeToRoom } from "../services/socketio.service";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const WS_URL = 'https://ws-planning-poker.onrender.com';

function Users(params) {
    const { users, votes, flipped} = params;
    const data = users.map((user) => {
        const vote = votes.find((vote) => vote.user_id === user.user_id);
        return {
            ...user,
            vote: vote ? vote.value : null
        }
    });
    return (<div className="card-table__body">
        {data.map((user, index) => (
            <div key={user.username} className='card-table__body__item'>
                <div className="card-table__body__card" key={index}>
                    {!flipped ? <div className={`card-table__body__card__content ${user.vote ? "voted" : ""}`}> {user.vote ? <BsCheck2Circle /> : '-'} </div> :
                    <div className={`card-table__body__card__content ${user.vote ? "reveal" : ""}`}> {user.vote ?? '-'} </div>} 
                </div>
                <div className="card-table__body__card__footer">
                    <p>{user.username} <span className="tooltip tooltip--bottom" data-tooltip="Verified user">{user.id ? <MdVerified /> : ''}</span></p>
                </div>
            </div>
        ))}
    </div>);
}

function LoginSection({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    function logInUser() {
        if (!username.trim()) {
            return;
        }
        axios.post(WS_URL + '/login', {
            username, password
        })
            .then((response) => {
                console.log(response);
                const token = response.data.session.access_token;
                onLogin && onLogin(token);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function LoginAsGuest() {
        if (!username.trim()) {
            return;
        }
        axios.post(WS_URL + '/login/temporal', {
            username, password
        })
            .then((response) => {
                console.log(response);
                const token = response.data.session.access_token;
                onLogin && onLogin(token);
            })
            .catch((error) => {
                console.log(error);
            })
    }     

    function RegisterUser() {
        if (!username.trim()) {
            return;
        }
        axios.post(WS_URL + '/register', {
            username, password, email
        })
            .then((response) => {
                console.log(response);

                //onLogin && onLogin(token);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <div className='row'>
            <div className="six columns bordered-right centered">
                <h6><strong>Welcome.</strong> Please login.</h6>
                <br />
                <fieldset>
                    <input className='custom-form-item' type="text" onInput={(e) => setUsername(e.target.value)} placeholder='Email' required></input>
                    <input className='custom-form-item' type="password" onInput={(e) => setPassword(e.target.value)} placeholder='Password' required></input>
                    <br />
                    <button type="submit" onClick={() => logInUser()} className="button-primary">Login</button>
                </fieldset>

            </div>
            {/*<div className="six columns centered">
                <h6><strong>New here?</strong> Register.</h6>
                <br />
                <fieldset>
                    <input className='custom-form-item' type="email" onInput={(e) => setEmail(e.target.value)} placeholder='Email' required></input>
                    <input className='custom-form-item' type="text" onInput={(e) => setUsername(e.target.value)} placeholder='Username' required></input>
                    <input className='custom-form-item' type="password" onInput={(e) => setPassword(e.target.value)} placeholder='Password' required></input>
                    <br />
                    <button type="submit" onClick={() => RegisterUser()} className="button-primary">Register</button>
                </fieldset>

                <AppleLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with Apple</span></AppleLoginButton>
                <GithubLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with GitHub</span></GithubLoginButton>
                <DiscordLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with Discord</span></DiscordLoginButton>
                <GoogleLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with Google</span></GoogleLoginButton> 
            </div>*/}
            <div className="six columns centered">
                <h6><strong>New here?</strong> Login as Guest.</h6>
                <br />
                <fieldset>
                    <input className='custom-form-item' type="text" onInput={(e) => setUsername(e.target.value)} placeholder='Username' required></input>
                    <br />
                    <button type="submit" onClick={() => LoginAsGuest()} className="button-primary">Login</button>
                </fieldset>

                {/* <AppleLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with Apple</span></AppleLoginButton>
                <GithubLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with GitHub</span></GithubLoginButton>
                <DiscordLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with Discord</span></DiscordLoginButton>
                <GoogleLoginButton iconSize="14px" className="custom-login-button" onClick={() => alert("Hello")}><span>Login with Google</span></GoogleLoginButton> */}
            </div>
        </div>
    );
}

function VoteCards(params) {
    const { room, issue } = params;
    const cards = ['1/2', '1', '2', '3', '5', '8', '16', '24', '48', '?'];

    function handleVoteCard(card) {
        console.log(card);
        voteIssue(issue, card, room);
    }

    return (
        <div className="twelve columns">
            <div className="card-table__body">
                {cards.map((card, index) => (
                    <div onClick={() => handleVoteCard(card)} className="card-table__body__card__me" key={index}>
                        <div className="card-table__body__card__content__me">{card}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function CreateRoom({ onCreate, isGuest }) {
    const [roomId, setRoomId] = useState('');
    const access_token = localStorage.getItem('token');
    function newRoom() {
        axios.post(WS_URL + '/rooms/create', {}, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
            .then((response) => {
                const room = response.data.roomId;
                console.log('room', room)
                joinRoom(room);

                onCreate && onCreate(room);
            })
            .catch((error) => { console.log(error); })
    }

    function joinToRoom() {
        console.log(roomId)
        joinRoom(roomId);
        onCreate && onCreate(roomId);
    }

    if(isGuest){
        return (
            <>
                <button onClick={() => newRoom()}>
                    Create Room
                </button>
                &nbsp;
                <input type="text" onInput={(e) => setRoomId(e.target.value)} placeholder='RoomId' required></input>
                <button onClick={() => joinToRoom()}>
                    Join Room
                </button>
            </>);
    }else{
        return (
            <>
                <input type="text" onInput={(e) => setRoomId(e.target.value)} placeholder='RoomId' required></input>
                <button onClick={() => joinToRoom()}>
                    Join Room
                </button>
            </>);
    }
   
}

function PlanningPoker() {
    const location = useLocation();
    const params = new URLSearchParams(location.hash.substr(1)); // Obtenemos los parámetros de la URL

    const tableRef = useRef(null);
    const [roomId, setRoomId] = useState('');
    const [username, setUsername] = useState('');
    const [issueId, setIssueId] = useState('8978');
    const [token, setToken] = useState('');
    const [users, setUsers] = useState([]);
    const [issues, setIssues] = useState([]);
    const [votes, setVotes] = useState([]);
    const [flipped, setFlipped] = useState(false);
    const [isGuest, setIsGuest] = useState(false);

    useEffect(() => {
        if (token) {
            initiateSocketConnection(token);
            localStorage.setItem('token', token);
            const decoded = jwt_decode(token);

            setUsername(decoded.user_metadata.username);

            subscribeToUsers((err, data) => {
                //setUsers(data);
            });

            subscribeToRoom((err, data) => {
                setUsers(data?.participants ?? []);
                setVotes(data?.votes ?? []);
                console.log('roomData',data, err);
            });
            return () => {
                disconnectSocket();
            }
        }
    }, [token]);

    const access_token = params.get('access_token');
    if (access_token && !token) {
        localStorage.setItem('token', access_token);
        const decoded = jwt_decode(access_token);
        setToken(access_token)
        setUsername(decoded.user_metadata.username);
        console.log(decoded);
    }
    
    if(!access_token && !token){
        if(localStorage.getItem('token')){
            const decoded = jwt_decode(localStorage.getItem('token'));
            if(decoded.exp < Date.now() / 1000){
                localStorage.removeItem('token');
            }else{
                setToken(localStorage.getItem('token'));
            }
        }
    }

    return (
        <>
            <h6 className="title-header">PLANNING POKER </h6>
            {!roomId ? <><p>Planning poker, also called Scrum poker, is a consensus-based, gamified technique for estimating, 
                mostly used for timeboxing in Agile principles. In planning poker, members of the group make estimates by playing
                 numbered cards face-down to the table, instead of speaking them aloud. 
                 The cards are revealed, and the estimates are then discussed</p>
                 
                 <div className='row'>
                 <div className='four columns bordered-right'>
                   Select a server:
                </div>
                <div className='eight columns'>
                    <select >
                        <option>ws-planning-poker.onrender.com</option>
                        <option disabled>ws-planning-poker.us-west-cloud (disabled)</option>
                        <option disabled>ws-planning-poker.nyc1-cloud (disabled)</option>
                    </select>
                </div>
                 </div>
                
                 </> : null }
            {!token ? <LoginSection onLogin={setToken} /> : 
                
            <div className="card-container">
                <div className="card-table" ref={tableRef}>
                    {!roomId ? <CreateRoom isGuest={isGuest} onCreate={setRoomId} /> : (
                        <>
                            <div className='row'>
                                <div className='eight columns bordered-right'>
                                    <div className="card-table__header">
                                        <div className="card-table__header__title">Welcome <b>{username}</b>, Session ID : <b>{roomId}</b></div>
                                    </div>
                                    <Users users={users} votes={votes} flipped={flipped}/>
                                </div>
                                <div className='four columns'>
                                    {/* <div className='prev-vote'>2</div>
                                    <div className='voting'>3</div>
                                    <div className='next-vote'>4</div>*/}

                                    <div>
                                        <h6 className="title-issue">VOTTING ISSUE: {issueId}</h6>
                                        <p className='description-issue'>
                                            <span>Raising impediments to the Scrum Master so they can be handled in a timely manner</span> <br/><br/>
                                            
                                            <span>Status: <b>Voting</b></span> <br/>
                                            <span>Average: <b>16</b></span><br/>
                                            <span>View Issue: <a href="#" target="_blank">Open</a> <TbExternalLink/></span> <br/>

                                        </p>
                                    </div>
                                    <hr/>
                                    <div className="row centered">
                                        <div className='six columns'>
                                            <button onClick={() => alert()}><MdKeyboardBackspace/></button>
                                        </div>
                                        <div className='six columns'>
                                            <button onClick={() => alert()}>NEXT STORY</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='four columns'>
                                            <button onClick={() => setFlipped(!flipped)}><BsPhoneFlip /> FLIP CARDS</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <VoteCards issue={issueId} room={roomId} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            }

        </>
    );
}

export default PlanningPoker;
