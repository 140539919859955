import React, { useState } from 'react';

function Home() {
    return (
        <>
            <h6 className="title-header">Home</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan risus et pulvinar ornare. Maecenas ante metus, ullamcorper id maximus quis, interdum eu nisl.</p>

        </>
    );
}

export default Home;
