import React, { useState } from 'react';

function VSExtensions() {
    return (
        <>
            <h6 className="title-header">Blank page </h6>
            <p>
                Under construction... 
            </p>
  
        </>
    );
}

export default VSExtensions;
