import React, { useState } from "react";
import { BsLightningChargeFill } from 'react-icons/bs';
import "./Menu.css";
import { Link, useLocation } from 'react-router-dom';

function Hamburger(props) {
    //const [open, setOpen] = useState(false);
    const { open, setOpen } = props;
    return (
        <div className="menu-hamburger">
            <div className={`hamburger ${open ? "open" : ""}`} onClick={() => setOpen(!open)}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    );
}

export default Hamburger;
